/*
 ** Manter esse arquivo organizado por ordem alfabética
 */
export enum FeatureFlags {
  CS_CHAT = 'CS_CHAT',
  TRADE_3_0 = 'TRADE_3_0',
  PARTNER_TYPEFORM = 'PARTNER_TYPEFORM',
  DISABLE_WARREN_BUILDER = 'DISABLE_WARREN_BUILDER',
  PLATFORM_MAINTENANCE = 'br.com.warren.foundation.platform-maintenance',
  FEATURE_SURVEY = 'br.com.warren.ip-tribe-pex.csat',
  TRADE_ALLOCATION_SUBSCRIBES = 'br.com.warren.trade.subscription-listing',
  TRADE_EVENTS_PROVISIONED = 'br.com.warren.trade.enable-provisioned-events',
  WALLET_WITHDRAW_BY_PRODUCT = 'br.com.warren.pro.redeem-by-products',
  TRADE_ENABLE_NEW_BROKER_CLIENT_FLOW = 'br.com.warren.trade.enable-new-broker-client-flow',
  ENABLE_BROKERAGE_FEE = 'br.com.warren.broker.enable-brokerage-fee',
  REGULATORY_UPDATE = 'br.com.warren.client-onboarding.regulatory-update',
  ENABLE_HISTORICAL_OPTIONS_LIST = 'br.com.warren.product-picking.enable-historical-options-list',
  ENABLE_BLOCK_PLATAFORM_ON_MOBILE_DEVICES = 'br.com.warren.experience.block-web-platform-on-mobile',
  ENABLE_PRODUCT_SHELF = 'br.com.warren.experience.enable-product-shelf',
  ENABLE_PORTABILITY = 'br.com.warren.experience.enable-portability-features',
  ENABLE_PRODUCT_SHELF_INVESTMENT_WITHDRAW = 'br.com.warren.experience.enable-product-shelf-investment-withdraw'
}
