import { render, staticRenderFns } from "./ProductsViewHero.vue?vue&type=template&id=84e75cdc&scoped=true&"
import script from "./ProductsViewHero.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ProductsViewHero.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./ProductsViewHero.vue?vue&type=style&index=0&id=84e75cdc&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84e75cdc",
  null
  
)

export default component.exports